@import "~@/erp/styles/variables/variables.scss";






















































































































































































































































































































































































































































































































































































































































.el-form-item--small .el-form-item__error {
  padding-top: 2px;
  position: relative;
  left: -50px;
}

.padding5 {
  padding: 5px;
}

.product-info {
  display: flex;
  padding: 10px;
  text-align: left;

  .pro-item {
    width: 100%;
    white-space: pre-wrap;
    margin-bottom: 5px;
  }

  .left {
    flex: 1;
  }

  p {
    display: flex;
    flex-direction: column;

    .el-tag {
      margin-bottom: 5px;
      width: 90px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.whi {
  white-space: pre-wrap;
}

.fixed-table {
  .el-table__body-wrapper {
    display: none !important;
  }
}
